import {useState,useRef} from 'react';
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { Check2Circle } from 'react-bootstrap-icons';
import stars from "../img/4.7_Rating.svg";
import CurriculumModal from "./Modal";
import ModalForm from './Modal3';
import emailjs from '@emailjs/browser';
import './Home.css';

const Home = () => {
    const [modalShow, setModalShow] = useState(false);
  const [modalFormShow,setModalFormShow] = useState(false);

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleModalFormClose =()=> setModalFormShow(false);
  const handleModalFormShow =()=> setModalFormShow(true);

  const form = useRef();

    const formInitialDetail = {
        name: '',
        last: '',
        email: '',
        phone: '',
        message: '',
        qualification: ''
    };

    const [formDetails, setFormDetails] = useState(formInitialDetail);
    const [buttonText, setButtonText] = useState('Submit');
    const [status, setStatus] = useState({});

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setButtonText('Submiting...');
        console.log("Form Details:", formDetails); 

             
        
        try {
            await emailjs.sendForm('service_o0bp90e', 'template_kvpqfec', form.current, 'VWsEdichg5c76MiDG');
            setStatus({ success: true, message: 'Message sent successfully' });
        } catch (error) {
            console.error('EmailJS send error:', error);
            setStatus({ success: false, message: 'Something Went Wrong' });
        }
        
        setButtonText('Submit');
        setFormDetails(formInitialDetail);
    };
  
    return (
        <section id='home' className='home'>
            <div className="home-container">
                <Row className="justify-content-between">
                    <Col
                        md={10}
                        lg={6}
                        xl={5}                       
                    >
                        <h1 style={{color: '#fff'}}>Your Launchpad to Future Success with Brainalyst's <span style={{color: 'rgb(231 121 55)',fontWeight: '700'}}>Data Science 360 Course</span></h1>
                        <p style={{color: '#fff'}}>Fuel Your Passion into Expertise!</p>
                        <ul style={{padding: '0'}}><li><Check2Circle/> Get a 100% Paid Internship*</li>
                        <li><Check2Circle/> Hackathons & Webinars</li>
                        <li><Check2Circle/> Prestigious Certification</li>
                        <li><Check2Circle/> Lifetime LMS Access</li>
                        <li><Check2Circle/>Referral Rewards</li></ul>
                        <div className='d-inline-flex align-items-center mb-0'><span className='fs-2 me-2 lh-1 fw-bold' style={{color: '#fff'}}>4.9</span>
                        <img src={stars} alt="ratings" /><span style={{ color: '#fff'}} >| 534 Reviews</span></div>
<div><button onClick={handleModalFormShow} style={{padding: '0.5rem',
    margin: '2rem',border: 'none',background: '#1070fd',color: '#fff'}}>Enroll Now</button>
<button onClick={handleModalShow} className="Download-course-btn">Douwnload Brocure</button></div>
                    </Col>
                    <Col md={10} lg={6} xl={5}>
                        <Card className="custom-card">
                            <div style={{display: 'flex',justifyContent: 'center'}}><h3>Connect With Us</h3></div>
                            <Card.Body>
                                <Form ref={form} onSubmit={handleOnSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicFirstName">
                                        <Form.Control type="text" name="name" value={formDetails.name} placeholder="First Name" onChange={(e) => onFormUpdate('name', e.target.value)}  required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicLastName">
                                        <Form.Control type="text" name='last' value={formDetails.last} placeholder="Last Name" onChange={(e) => onFormUpdate('last', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicQualification">
                                        <Form.Control type="text" name='qualification' value={formDetails.qualification} placeholder="Qualification" onChange={(e) => onFormUpdate('qualification', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPhone">
                                        <Form.Control type="tel" name='phone' value={formDetails.phone} placeholder="Contact No." onChange={(e) => onFormUpdate('phone', e.target.value)}  required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="email" name='email' value={formDetails.email} placeholder="Enter email" onChange={(e) => onFormUpdate('email', e.target.value)}  required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicMessage">
                                        <Form.Control type="text" name='message' value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)} />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="w-100">
                                    <span>{buttonText}</span>
                                    </Button>
                                    {status.message &&
                                    <Col>
                                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                    </Col>}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <CurriculumModal show={modalShow} handleClose={handleModalClose} />
      <ModalForm show={modalFormShow} handleClose={handleModalFormClose} />
        </section>
    );
};

export default Home;
