import React, { useState, useEffect } from "react";
import Odometer from "react-odometerjs";
import { Container, Row, Col, Card } from "react-bootstrap";
import TrackVisibility from "react-on-screen";
import "odometer/themes/odometer-theme-default.css";
import "./Project.css"; 
import p1 from '../img/p1.webp';
import p2 from '../img/p2.png';
import p3 from '../img/website_15662265.png';

const Project = () => {
  const [value1, setValue1] = useState(1);
  const [value2, setValue2] = useState(1);
  const [value3, setValue3] = useState(1);
  const [hasStarted, setHasStarted] = useState(false);

  const startCounters = () => {
    setValue1(100);
    setValue2(50);
    setValue3(2);
  };

  useEffect(() => {
    if (hasStarted) {
      startCounters();
    }
  }, [hasStarted]);

  return (
    <section id="project">
      <Container>
        {/* <div className="project-header">
          <h2>Projects</h2>
          <hr />
        </div> */}
        <Row className="project-row">
          <Col className="project-col">
            <TrackVisibility partialVisibility>
              {({ isVisible }) => {
                if (isVisible && !hasStarted) {
                  setHasStarted(true);
                }
                return (
                  <Card className="project-card">
                    <Card.Body className="project-card-body">
                      <Card.Title className="project-card-title">
                    <Card.Img variant="top" src={p1} alt="project" />
                        <h1>
                          <Odometer value={value1} format="(.ddd),dd" />+
                        </h1>
                        Assignments
                      </Card.Title>
                    </Card.Body>
                  </Card>
                );
              }}
            </TrackVisibility>
          </Col>
          <Col className="project-col">
            <TrackVisibility partialVisibility>
              {({ isVisible }) => {
                if (isVisible && !hasStarted) {
                  setHasStarted(true);
                }
                return (
                  <Card className="project-card">
                    <Card.Body className="project-card-body">
                      <Card.Title className="project-card-title">
                    <Card.Img variant="top" src={p2} alt="project" />
                        <h1>
                          <Odometer value={value2} format="(.ddd),dd" />+
                        </h1>
                        Industry Relevant Case Studies
                      </Card.Title>
                    </Card.Body>
                  </Card>
                );
              }}
            </TrackVisibility>
          </Col>
          <Col className="project-col">
            <TrackVisibility partialVisibility>
              {({ isVisible }) => {
                if (isVisible && !hasStarted) {
                  setHasStarted(true);
                }
                return (
                  <Card className="project-card">
                    <Card.Body className="project-card-body">
                      <Card.Title className="project-card-title">
                    <Card.Img variant="top" src={p3} alt="project" />
                        <h1>
                          <Odometer value={value3} format="(.ddd),dd" />
                        </h1>
                        Capstone Projects
                      </Card.Title>
                    </Card.Body>
                  </Card>
                );
              }}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Project;