import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Check } from "react-bootstrap-icons";
import ModalForm from './Modal3';
import j1 from "../img/j1.webp";
import '../components/Jobprep.css'; 

const Jobprep = () => {
    const [modalFormShow, setModalFormShow] = useState(false);

    const handleModalFormClose = () => setModalFormShow(false);
    const handleModalFormShow = () => setModalFormShow(true);

    return (
        <section style={{ background: "#ddd", padding: '5rem 0' }}>
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} className="text-center mb-4 mb-md-0">
                        <img src={j1} alt="curriculum" style={{ width: "100%", maxWidth: "400px" }} />
                    </Col>
                    <Col xs={12} md={6}>
                        <h2 className='lh-sm mb-5'>Get Prepared for Job with Us..</h2>
                        <Row>
                            <Col xs={12} md={6}>
                                <ul className="list-group list-unstyled">
                                    <li className='d-flex align-items-center mb-1'>
                                        <div className="icon-container"><Check /></div>Resume Building.
                                    </li>
                                    <li className='d-flex align-items-center mb-1'>
                                        <div className="icon-container"><Check /></div>Industry Mentorship Session
                                    </li>
                                    <li className='d-flex align-items-center mb-1'>
                                        <div className="icon-container"><Check /></div>General Aptitude Preparation Sessions
                                    </li>
                                    <li className='d-flex align-items-center mb-1'>
                                        <div className="icon-container"><Check /></div>GitHub Repository Building
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} md={6}>
                                <ul className="list-group list-unstyled">
                                    <li className='d-flex align-items-center mb-1'>
                                        <div className="icon-container"><Check /></div>LinkedIn Profile Building
                                    </li>
                                    <li className='d-flex align-items-center mb-1'>
                                        <div className="icon-container"><Check /></div>Mock Interview
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <button onClick={handleModalFormShow} variant="primary" style={{ padding: '0.5rem', margin: '2rem', border: 'none', background: '#1070fd', color: '#fff' }}>Request a Call</button>
                    </Col>
                </Row>
            </Container>
            <ModalForm show={modalFormShow} handleClose={handleModalFormClose} />
        </section>
    );
}

export default Jobprep;
