import {useState} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import {Check} from "react-bootstrap-icons";
import CurriculumModal from "./Modal";
import c1 from "../img/c1.webp";

const Curriculum=()=>{
    const [modalShow, setModalShow] = useState(false);

    const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

    return(
        <section style={{background: "#ddd",padding: '5rem 0'}}>
            <Container>
                <Row>
                    <Col>
                    <div><h2 className='lh-sm mb-5'>Explore Our Comprehensive Learning Pathways</h2></div>
                    <Row>
                        <Col><ul><li className='list-group-item d-flex align-items-center mb-1'><div style={{minWidth: '2.5rem',width: '2.5rem',height: '2.5rem',fontSize: '1.125rem',background: 'rgba(29, 75, 64, 0.1)',borderRadius: '50%',marginRight: '1rem',display: 'flex',justifyContent: 'center',alignItems: 'center'}}><Check/></div>Advanced Excel for Data Analytics</li>
                        <li className='list-group-item d-flex align-items-center mb-1'><div style={{minWidth: '2.5rem',width: '2.5rem',height: '2.5rem',fontSize: '1.125rem',background: 'rgba(29, 75, 64, 0.1)',borderRadius: '50%',marginRight: '1rem',display: 'flex',justifyContent: 'center',alignItems: 'center'}}><Check/></div>VBA for Macros</li>
                        <li className='list-group-item d-flex align-items-center mb-1'><div style={{minWidth: '2.5rem',width: '2.5rem',height: '2.5rem',fontSize: '1.125rem',background: 'rgba(29, 75, 64, 0.1)',borderRadius: '50%',marginRight: '1rem',display: 'flex',justifyContent: 'center',alignItems: 'center'}}><Check/></div>SQL for Data Analytics</li>
                        <li className='list-group-item d-flex align-items-center mb-1'><div style={{minWidth: '2.5rem',width: '2.5rem',height: '2.5rem',fontSize: '1.125rem',background: 'rgba(29, 75, 64, 0.1)',borderRadius: '50%',marginRight: '1rem',display: 'flex',justifyContent: 'center',alignItems: 'center'}}><Check/></div>Data Analytics & Visualization with Tableau
                        </li></ul></Col>
                        <Col><ul><li className='list-group-item d-flex align-items-center mb-1'><div style={{minWidth: '2.5rem',width: '2.5rem',height: '2.5rem',fontSize: '1.125rem',background: 'rgba(29, 75, 64, 0.1)',borderRadius: '50%',marginRight: '1rem',display: 'flex',justifyContent: 'center',alignItems: 'center'}}><Check/></div>Data Analytics & Visualization with PowerBi</li>
                        <li className='list-group-item d-flex align-items-center mb-1'><div style={{minWidth: '2.5rem',width: '2.5rem',height: '2.5rem',fontSize: '1.125rem',background: 'rgba(29, 75, 64, 0.1)',borderRadius: '50%',marginRight: '1rem',display: 'flex',justifyContent: 'center',alignItems: 'center'}}><Check/></div>Data Science with Python (Including Machine Learning)</li>
                        <li className='list-group-item d-flex align-items-center mb-1'><div style={{minWidth: '2.5rem',width: '2.5rem',height: '2.5rem',fontSize: '1.125rem',background: 'rgba(29, 75, 64, 0.1)',borderRadius: '50%',marginRight: '1rem',display: 'flex',justifyContent: 'center',alignItems: 'center'}}><Check/></div>Data Science with R (Including Machine Learning)</li></ul>
                        <button onClick={handleModalShow} className="Download-course-btn">Douwnload Curriculum</button>
                        </Col>
                    </Row>
                    </Col>
                    <Col xs={12} md={6} className="text-center mb-4 mb-md-0">
                    <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}><img src={c1} alt="curriculum" style={{width: "100%",}}/></div>
                    </Col>
                </Row>
                <CurriculumModal show={modalShow} handleClose={handleModalClose} />
            </Container>
        </section>
    )
}
export default Curriculum;
