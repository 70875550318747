import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Hi1 from '../img/Communication.webp';
import Hi2 from '../img/Curriculum.webp';
import Hi3 from '../img/Mock_Interview.webp';
import Hi4 from '../img/p2.png';
import Hi5 from '../img/LIve_Doubts.webp';
import Hi6 from '../img/Labs.webp';
import Hi7 from '../img/Placement_Assistance.webp';
import Hi8 from '../img/EMI.webp';

const highlights = [
    { img: Hi1, text: "300+ Hours of Recorded Classes" },
    { img: Hi2, text: "180+ Hours of Live Online Classes" },
    { img: Hi3, text: "100% Job Support with Hiring Partners" },
    { img: Hi4, text: "Dedicated Daily Doubt Sessions" },
    { img: Hi5, text: "Live Doubt Clarification" },
    { img: Hi6, text: "Labs For Hands-On Practice" },
    { img: Hi7, text: "Job Portal with 100+ Jobs" },
    { img: Hi8, text: "50%-400% salary hike" }
];

const Highlight = () => {
    return (
        <section className="py-5">
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1 style={{ fontWeight: '900', fontFamily: 'margaine sans-serif' }}>
                            Course <span style={{ color: 'rgb(231 121 55)' }}>Highlight</span>
                        </h1>
                    </Col>
                </Row>
                <Row>
                    {highlights.map((highlight, index) => (
                        <Col md={3} xs={12} className="mb-4" key={index}>
                            <Card className="h-100 d-flex flex-row align-items-center" style={{ background: 'aliceblue' }}>
                                <div className="p-2">
                                    <img
                                        src={highlight.img}
                                        className="img-fluid"
                                        style={{ maxHeight: '90px' }}
                                        alt="highlight-img"
                                    />
                                </div>
                                <Card.Body className="d-flex align-items-center">
                                    <Card.Text className="m-0 text-center">
                                        {highlight.text}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default Highlight;
