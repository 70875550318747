import React, { useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import emailjs from '@emailjs/browser';



const serviceId = "service_o0bp90e";
const templateId = "template_kvpqfec";

const ModalForm = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({ name: "", phone: "", email: "" });
  const [status, setStatus] = useState({});
  const formRef = useRef();

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    

    try {
      const emailResult = await emailjs.sendForm(serviceId, templateId, formRef.current, 'VWsEdichg5c76MiDG');
      console.log("Email sent successfully:", emailResult.text);
      setStatus({ success: true, message: 'Message sent successfully' });

      handleClose();
    } catch (error) {
      console.error("Error sending email:", error);
      setStatus({ success: false, message: 'Failed to send message' });
    }
  };

  

  return (
    <>
      <Modal show={show} onHide={handleClose} style={{ marginTop: "65px" }}>
        <Modal.Header closeButton style={{ background: "#0d6efd" }}>
          <Modal.Title style={{ fontFamily: 'Margarine sans-serif', fontWeight: '400', fontStyle: 'normal',color: '#fff' }}>
          Join the Data Science & Analytics Revolution Now
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label style={{ color: "#121212" }}>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label style={{ color: "#121212" }}>Whatsapp No.</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label style={{ color: "#121212" }}>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              style={{ marginTop: "10px" }}
            >
              Submit
            </Button>
          </Form>
          {status.message && (
            <p style={{ marginTop: '10px', color: status.success ? 'green' : 'red' }}>{status.message}</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalForm;