import {Container,Accordion,Row,Col} from 'react-bootstrap';
import {useState} from 'react';
import CourseDetailsModal from "./Modal2";
import './Eligibilty.css';

const Eligible=()=>{
    const [courseDetailsModalShow, setCourseDetailsModalShow] = useState(false);

    const handleCourseDetailsModalClose = () => setCourseDetailsModalShow(false);
    const handleCourseDetailsModalShow = () => setCourseDetailsModalShow(true);
    return(
        <section style={{paddingTop: '7.5rem',paddingBottom: '7.5rem'}}>
            <Container>
                <Row className='justify-content-between'>
                    <Col lg={6}>
                    <h2 style={{ fontSize: '4rem' }}>Who Can be a Part of the <span style={{ color: 'rgb(231 121 55)', fontWeight: '700' }}>Data Science</span> 360 Course ?</h2>
                    <button onClick={handleCourseDetailsModalShow} className="Download-course-btn">Download Course</button>
                    </Col>
                    <Col lg={5}>
                    <Accordion className="accordion accordion-steps">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h1>Students</h1>
                                </Accordion.Header>
                                <Accordion.Body>
                                    It's an ideal choice for learners who want to start or transition into careers as a Data Analyst, Data Scientist, Business Analyst, or ML Engineer
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <h1>Fresh Graduate</h1>
                                </Accordion.Header>
                                <Accordion.Body>
                                    Gain the knowledge and experience needed for your dream job in data analytics, data science, artificial intelligence, big data, or cloud computing
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    <h1>Working Professional</h1>
                                </Accordion.Header>
                                <Accordion.Body>
                                    Dive into the exciting world of data science with a blend of essential tools, techniques, and real-world projects. Whether you’re launching your dream career or transitioning to roles like data analyst, data scientist, or ML engineer.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            <CourseDetailsModal show={courseDetailsModalShow} handleClose={handleCourseDetailsModalClose} />
        </section>
    )
}
export default Eligible;