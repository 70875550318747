import { Container, Row, Col, Table} from "react-bootstrap";
import {useState} from 'react';
import { Check } from "react-bootstrap-icons";
import ModalForm from './Modal3';
import { X } from "react-bootstrap-icons";

export const Feature = () => {
  const [modalFormShow,setModalFormShow] = useState(false);

  const handleModalFormClose =()=> setModalFormShow(false);
  const handleModalFormShow =()=> setModalFormShow(true);
  return (
    <section className="feature" id="feature">
      <Container>
        <Row>
          <div className="Feature-heading">
            <h2
              style={{
                fontFamily: "Margarine, sans-serif",
                fontStyle: "normal",
                fontWeight: '900',
                padding: '3rem'
              }}
            >
              Why Should You Choose <span style={{color: 'rgb(231 121 55)'}}> Brainalyst Data Science 360 course ?</span> 
            </h2>
          </div>
       
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{background: '#ddd'}}>Feature</th>
                  <th style={{background: '#ddd'}}>Udemy</th>
                  <th style={{background: '#ddd'}}>Coursera</th>
                  <th style={{background: '#ddd'}}>Scaler</th>
                  <th style={{background: '#ddd'}}>Cloud ML</th>
                  <th style={{border: '2px solid rgb(231, 121, 55)',background: 'rgb(231, 121, 55)',color: '#fff',}}>Brainalyst</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Afordable Course</th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th style={{borderLeft: '2px solid rgb(231, 121, 55)',borderRight: '2px solid rgb(231, 121, 55)'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th style={{background: '#ddd'}}>Hands on Learning</th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th style={{background: '#ddd',borderLeft: '2px solid rgb(231, 121, 55)',borderRight: '2px solid rgb(231, 121, 55)'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>1:1 Mentorship</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{borderLeft: '2px solid rgb(231, 121, 55)',borderRight: '2px solid rgb(231, 121, 55)'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th style={{background: '#ddd'}}>Live 1:1 Doubt Sessions</th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th style={{background: '#ddd',borderLeft: '2px solid rgb(231, 121, 55)',borderRight: '2px solid rgb(231, 121, 55)'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>Aptitude Test Training</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th style={{borderLeft: '2px solid rgb(231, 121, 55)',borderRight: '2px solid rgb(231, 121, 55)'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th style={{background: '#ddd'}}>Live Interactive Online Classes</th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd',borderLeft: '2px solid rgb(231, 121, 55)',borderRight: '2px solid rgb(231, 121, 55)'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>100% Paid Internship* On Completion</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{borderLeft: '2px solid rgb(231, 121, 55)',borderRight: '2px solid rgb(231, 121, 55)'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th  style={{background: '#ddd'}}>LMS Access After Completion</th>
                  <th style={{background: '#ddd'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{background: '#ddd'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th style={{background: '#ddd',borderLeft: '2px solid rgb(231, 121, 55)',borderRight: '2px solid rgb(231, 121, 55)'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>Dedicated Course Books</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th style={{borderLeft: '2px solid rgb(231, 121, 55)',borderRight: '2px solid rgb(231, 121, 55)',borderBottom: '2px solid rgb(231, 121, 55)'}}>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col style={{display: 'flex',justifyContent: 'center',alignItems: 'center',flexDirection: 'column'}}>
          <h2>Master Brainalyst Data Science 360 Program</h2>
          <p>Transform your career with the Data Science 360 Course, the ultimate all-in-one solution for a career in data. This premier program offers comprehensive training in data analytics, visualization, and machine learning, tailored for both beginners and those seeking a career shift. Benefit from over 300 hours of expertly crafted content, interactive live sessions, and invaluable bonuses like a paid internship and lifetime access. ith Brainalyst, you gain the expertise and credentials to excel and make a significant impact in the data world. <br />Step into the Future of Data Science with Brainalyst! </p>
          <button onClick={handleModalFormShow} style={{padding: '0.5rem',
    margin: '2rem',border: 'none',background: '#1070fd',color: '#fff'}}>Get More Details</button>
          </Col>
        </Row>
        <ModalForm show={modalFormShow} handleClose={handleModalFormClose} />
      </Container>
    </section>
  );
};